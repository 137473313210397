import React, {useRef} from 'react';
import {useReactToPrint} from 'react-to-print';
import RentAffordabilityCalculator from '../containers/RentAffordabilityCalculator';

export default function RentAffordabilityCalculatorApp() {
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: 'Rent Affordability Calculator',
  });
  return (
    <RentAffordabilityCalculator ref={componentRef} handlePrint={handlePrint} />
  );
}
