import {Button, Card, Input, Spin} from 'antd';
import React, {useEffect, useState} from 'react';
import remarkGfm from 'remark-gfm';
import StyledReactMarkdown from '../StyledReactMarkdown/StyledReactMarkdown';
import BeatLoader from 'react-spinners/BeatLoader';
import {AnimatePresence, motion} from 'framer-motion';
import CopyTextButton from '../CopyTextButton/CopyTextButton';

const API_URL = process.env.REACT_APP_FIREBASE_API_URL;
const GeniAssist = () => {
  const [messages, setMessages] = useState([]); // Chat history
  const [input, setInput] = useState(''); // User input
  const [isStreaming, setIsStreaming] = useState(false); // Streaming status
  const [isLoading, setIsLoading] = useState(false); // Loading status
  const [session, setSession] = useState(null); // Assistant session
  const [responseCount, setResponseCount] = useState(0);
  const hasSession = React.useRef(null);
  const messagesEndRef = React.useRef(null);
  const scrollToBottom = () => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'end',
        inline: 'end',
      });
    }
  };
  const sendMessage = React.useCallback(async () => {
    if (!input.trim()) return;

    if (responseCount >= 10) {
      setMessages((prev) => [
        ...prev,
        {
          role: 'assistant',
          content:
            'You have reached the maximum number of responses for this session.',
        },
      ]);
      return;
    }

    // Add user message to the chat history
    const newUserMessage = {role: 'user', content: input};
    setMessages((prev) => [...prev, newUserMessage]);
    setInput(''); // Clear input field

    try {
      setIsStreaming(true);

      // Stream response from the assistant
      const response = await fetch(API_URL + '/openai/assistant/chat', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'text/event-stream',
        },
        body: JSON.stringify({
          message: newUserMessage,
          threadId: session.id,
          assistant: 'asst_sXIg9fCrLQhA5NjIKnjhYW1x',
        }),
      });

      if (!response.ok) {
        throw new Error('Failed to connect to the assistant');
      }

      // Read the stream
      const reader = response.body.getReader();
      const decoder = new TextDecoder('utf-8');
      let assistantMessage = {role: 'assistant', content: ''};

      while (true) {
        const {done, value} = await reader.read();
        if (done) break;

        // Decode the chunk and update the assistant's message
        const chunk = decoder.decode(value);
        // console.log('chunk', chunk);
        assistantMessage.content += chunk;

        // Append the updated message to the chat
        setMessages((prev) => {
          const updated = [...prev];
          const assistantIndex = updated.reduce((lastIndex, msg, index) => {
            return msg.role === 'assistant' ? index : lastIndex;
          }, -1);

          if (assistantIndex === updated.length - 1) {
            updated[assistantIndex].content += chunk;
          } else {
            updated.push({...assistantMessage});
          }
          return updated;
        });
      }
      setResponseCount((prevCount) => prevCount + 1);
    } catch (error) {
      console.error('Error:', error);
      setMessages((prev) => [
        ...prev,
        {role: 'assistant', content: 'Error occurred while fetching response.'},
      ]);
    } finally {
      setIsStreaming(false);
    }
  }, [input, responseCount, session]);

  const initializeThread = async () => {
    try {
      setIsLoading(true);
      const response = await fetch(API_URL + '/openai/assistant/thread');
      const data = await response.json();
      hasSession.current = true;
      setSession(data);
    } catch (error) {
      console.error('Error:', error);
      setMessages((prev) => [
        ...prev,
        {role: 'assistant', content: 'Error occurred while fetching response.'},
      ]);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (!hasSession.current) {
      initializeThread();
    }
  }, []);

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  useEffect(() => {
    const preventParentScroll = (event) => {
      if (event.target === window) {
        event.preventDefault();
      }
    };

    window.addEventListener('scroll', preventParentScroll, {passive: false});

    return () => {
      window.removeEventListener('scroll', preventParentScroll);
    };
  }, []);

  return (
    <Card
      title="Geni&trade; - AI Virtual Assistant"
      style={{
        maxWidth: '80%',
        margin: '20px auto',
      }}
    >
      <div
        className="chat-box"
        style={{
          border: '1px solid #ccc',
          borderRadius: '5px',
          padding: '10px',
          height: '400px',
          overflowY: 'auto',
          marginBottom: '10px',
        }}
      >
        <AnimatePresence>
          {messages.map((msg, index) => (
            <motion.div
              key={index}
              style={{
                textAlign: msg.role === 'user' ? 'right' : 'left',
                margin: '12px 0',
              }}
              initial={{opacity: 0, y: 10}}
              animate={{opacity: 1, y: 0}}
              exit={{opacity: 0}}
            >
              <span
                style={{
                  display: 'inline-block',
                  padding: '0 1.5em',
                  borderRadius: '24px',
                  backgroundColor: msg.role === 'user' ? '#e9ecef' : '#fffff',
                  maxWidth: '80%',
                  wordWrap: 'break-word',
                }}
              >
                <StyledReactMarkdown
                  children={msg.content}
                  remarkPlugins={[remarkGfm]}
                />
                {msg.role === 'user' ? null : (
                  <CopyTextButton text={msg.content} />
                )}
              </span>
            </motion.div>
          ))}
        </AnimatePresence>
        <Spin
          spinning={isStreaming}
          indicator={
            <div style={{width: 'auto', padding: '0 1em'}}>
              <BeatLoader size={10} color="#cb47b8" />
            </div>
          }
        />
        <div ref={messagesEndRef}></div>
      </div>
      <div style={{display: 'flex'}}>
        <Input
          type="text"
          value={input}
          onPressEnter={sendMessage}
          onChange={(e) => setInput(e.target.value)}
          placeholder="Type your message..."
          style={{
            flexGrow: 1,
            padding: '10px',
            borderRadius: '5px',
            border: '1px solid #ccc',
            marginRight: '10px',
          }}
        />
        <Button
          size="large"
          disabled={isStreaming}
          loading={isLoading}
          onClick={sendMessage}
          style={{
            padding: '10px 20px',
            backgroundColor: '#541388',
            color: '#fff',
            border: 'none',
            borderRadius: '5px',
            cursor: 'pointer',
          }}
        >
          {isStreaming ? (
            <span
              style={{
                width: '24px',
                marginRight: '12px',
                marginBottom: '10px',
                display: 'inline-block',
              }}
            >
              <Spin
                spinning={true}
                indicator={<BeatLoader size={10} color="#ffffff" />}
              />
            </span>
          ) : (
            'Send'
          )}
        </Button>
      </div>
    </Card>
  );
};

export default GeniAssist;
