import {analytics, auth} from './firebase';
import {
  GoogleAuthProvider,
  signInWithPopup,
  signInWithEmailAndPassword,
  signInWithCredential,
  createUserWithEmailAndPassword,
  sendPasswordResetEmail,
  getAdditionalUserInfo,
  signOut,
} from 'firebase/auth';
import {logEvent} from 'firebase/analytics';

export const getCurrentUser = () => {
  return new Promise((resolve, reject) => {
    const unsubscribe = auth.onAuthStateChanged((userAuth) => {
      unsubscribe();
      resolve(userAuth);
    }, reject);
  });
};
const googleProvider = new GoogleAuthProvider();
googleProvider.setCustomParameters({prompt: 'select_account'});

export const signInWithGoogleIdentity = async (credential) => {
  // console.log(credential);
  try {
    const res = await signInWithCredential(
      auth,
      GoogleAuthProvider.credential(credential)
    );
    // console.log('credential', res);
    return res;
  } catch (err) {
    throw err;
  }
};

export const signInWithGoogle = () =>
  signInWithPopup(auth, googleProvider)
    .then((res) => {
      // console.log('ƒ signInWithPopup', getAdditionalUserInfo(res));
      const {isNewUser} = getAdditionalUserInfo(res);
      if (isNewUser) {
        logEvent(analytics, 'sign_up', {method: 'Google'});
      }
      res.credential = GoogleAuthProvider.credentialFromResult(res);
      // console.log('popup', res);
      return res;
    })
    .catch((error) => {
      error.credential = GoogleAuthProvider.credentialFromError(error);
      throw error;
    });
// export const facebookProvider = new firebase.auth.FacebookAuthProvider();
// export const githubProvider = new firebase.auth.GithubAuthProvider();
// export const twitterProvider = new firebase.auth.TwitterAuthProvider();
// export const signInWithGithub = () => auth.signInWithPopup(githubProvider);
// export const signInWithTwitter = () => auth.signInWithPopup(twitterProvider);
// export const signInWithFacebook = () =>
//   auth
//     .signInWithPopup(facebookProvider)
//     .then(function(result) {
//       // This gives you a Facebook Access Token. You can use it to access the Facebook API.
//       var token = result.credential.accessToken;
//       // The signed-in user info.
//       var user = result.user;
//       console.log(`FB Token: ${token}`, `User: ${user}`);

//       // ...
//     })
//     .catch(function(error) {
//       // Handle Errors here.
//       console.error(error);

//       // ...
//     });

export const signInWithEmail = async (email, password) =>
  await signInWithEmailAndPassword(auth, email, password);

export const signUpWithEmailAndPassword = async (email, password) =>
  await createUserWithEmailAndPassword(auth, email, password);

export const resetPassword = (email) => sendPasswordResetEmail(auth, email);

export const signOutUser = () =>
  signOut(auth)
    .then(() => {
      // console.log('Sign-out successful');
      return 'success';
    })
    .catch((err) => {
      console.error(err);
      return;
    });

export default auth;
