import {CopyOutlined} from '@ant-design/icons';
import {Button, Tooltip, message} from 'antd';
import React from 'react';
import {convertMarkdownToHtml} from '../../library/helpers/markdown';

function CopyTextButton(props) {
  const handleCopyText = async () => {
    const html = convertMarkdownToHtml(props.text);

    await navigator.clipboard
      .write([
        new ClipboardItem({
          'text/html': new Blob([html], {type: 'text/html'}),
          'text/plain': new Blob([html], {type: 'text/plain'}),
        }),
      ])
      .then(() => {
        console.log('Text copied to clipboard');
        message.success('Text copied to clipboard');
      })
      .catch((error) => {
        console.error('Failed to copy text: ', error);
        message.error('Failed to copy text');
      });
  };

  return (
    <div style={{textAlign: 'left', marginTop: '5px'}}>
      <Tooltip title="Copy to clipboard">
        <Button
          icon={<CopyOutlined />}
          shape="round"
          size="small"
          color="default"
          variant="filled"
          onClick={handleCopyText}
        />
      </Tooltip>
    </div>
  );
}

export default CopyTextButton;
