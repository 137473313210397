import {useState} from 'react';
import {Col, Form, Input, Row, Slider, Space} from 'antd';
import {calculateRentAffordability} from '../../library/helpers/calculators';
import {Typography} from 'antd';
import RentAffordabilityResult from '../RentAffordabilityResult/RentAffordabilityResult';
const {Title} = Typography;

const CalculatorForm = (props) => {
  const [form] = Form.useForm();
  const [calculatorVals, setCalculatorVals] = useState();

  const handleChangedValues = () => {
    // console.log('ƒ handleChangedValues', changedValues);
    if (form.getFieldValue('netIncome')) {
      let calculatedCosts = calculateRentAffordability(form.getFieldsValue());
      setCalculatorVals(calculatedCosts);
    }
  };

  const marks = {
    30: '30%',
  };

  return (
    <Form
      layout="vertical"
      name="RentAffordabilityCalculator"
      form={form}
      initialValues={{rentExpenseRatio: 30}}
      onValuesChange={handleChangedValues}
    >
      <Title level={3}>
        Income <span style={{fontSize: '14px'}}>(monthly)</span>
      </Title>
      <Space size={24}>
        <Form.Item label="Net Income" name="netIncome">
          <Input prefix="$" />
        </Form.Item>
      </Space>
      <Title level={3}>
        Expenses <span style={{fontSize: '14px'}}>(monthly)</span>
      </Title>
      <Space size={14}>
        <Form.Item label="Transportation" name="transportation">
          <Input prefix="$" />
        </Form.Item>
        <Form.Item label="Food" name="food">
          <Input prefix="$" />
        </Form.Item>
      </Space>
      <Space size={14}>
        <Form.Item label="Utilities" name="utilities">
          <Input prefix="$" />
        </Form.Item>
        <Form.Item label="Insurance" name="insurance">
          <Input prefix="$" />
        </Form.Item>
      </Space>
      <Space size={14}>
        <Form.Item label="Entertainment" name="entertainment">
          <Input prefix="$" />
        </Form.Item>
        <Form.Item label="Other Expenses" name="otherExpense">
          <Input prefix="$" />
        </Form.Item>
      </Space>
      <Row gutter={14} style={{alignItems: 'center'}}>
        <Col span={12}>
          <Form.Item label="Rental Expense Ratio" name={'rentExpenseRatio'}>
            <Slider marks={marks} min={0} max={100} />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name={'rentExpenseRatio'}
            hasFeedback
            validateStatus={
              +form.getFieldValue('rentExpenseRatio') >= 40 &&
              +form.getFieldValue('rentExpenseRatio') < 50
                ? 'warning'
                : +form.getFieldValue('rentExpenseRatio') >= 50
                ? 'error'
                : 'success'
            }
          >
            <Input suffix="%&nbsp;" />
          </Form.Item>
        </Col>
      </Row>

      <RentAffordabilityResult {...calculatorVals} title="Rent Affordability" />
    </Form>
  );
};
export default CalculatorForm;
