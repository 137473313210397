import servicePlans from './servicePlans';
function roiCalculator(values) {
  // console.log('[helpers.js] ƒ roiCalculator', values);
  const {
    vacancyRate,
    rentIncome,
    downPayment,
    closingCosts,
    interestRate,
    loanTerm,
    otherIncome,
    improvements,
    propertyValue,
  } = values;

  const capEx = improvements || 0;
  const vacancyLoss = vacancyRate ? rentIncome * (vacancyRate / 100) * 12 : 0;
  const noi = netOperatingIncome(values);
  const cr = capRate({
    netIncome: noi.netOperatingIncome - vacancyLoss,
    propertyValue: values.propertyValue,
  });

  function monthlyPayment(p, n, i) {
    return (p * i * Math.pow(1 + i, n)) / (Math.pow(1 + i, n) - 1);
  }

  const annualDebtService =
    monthlyPayment(
      propertyValue - downPayment,
      loanTerm * 12,
      interestRate / 100 / 12
    ) * 12;
  // console.log(annualDebtService / 12);
  const annualCashFlow =
    noi.netOperatingIncome - vacancyLoss - annualDebtService;

  const cashOnCashReturn =
    annualCashFlow / (downPayment + closingCosts + capEx);

  const grossRentMultiplier = propertyValue / ((rentIncome + otherIncome) * 12);

  const debtServiceCoverageRatio =
    (noi.netOperatingIncome - vacancyLoss) / annualDebtService;

  return {
    capRate: cr.capRate,
    netOperatingIncome: noi.netOperatingIncome - vacancyLoss,
    cashOnCashReturn: cashOnCashReturn,
    grossRentMultiplier: grossRentMultiplier,
    debtServiceCoverageRatio: debtServiceCoverageRatio,
    annualCashFlow: annualCashFlow,
  };
}

function calculateMoveInCosts(values) {
  //console.log('[helpers.js] ƒ calculateProratedRent', values);
  const {rentAmount, daysInMonth, moveInDay, securityDeposit, otherCosts} =
    values;
  const proratedRent = (rentAmount / daysInMonth) * (daysInMonth - moveInDay);
  let totalMoveIn = proratedRent;

  if (securityDeposit) {
    totalMoveIn = totalMoveIn + parseFloat(securityDeposit);
  }
  if (otherCosts) {
    totalMoveIn = totalMoveIn + parseFloat(otherCosts);
  }

  return {proratedRent: proratedRent, totalMoveIn: totalMoveIn};
}

function calculateMoveOutCosts(values) {
  //console.log('[helpers.js] ƒ calculateProratedRent', values);
  const {rentAmount, daysInMonth, moveOutDay} = values;
  const proratedRent = (rentAmount / daysInMonth) * moveOutDay;

  return {proratedRent: proratedRent};
}

function netOperatingIncome(values) {
  //console.log('[helpers.js] ƒ netOperatingIncome', values);
  const {
    rentIncome,
    otherIncome,
    propertyTaxes,
    insurance,
    repairs,
    utilities,
    managementFees,
    otherExpense,
  } = values;
  const annualIncome = (rentIncome + otherIncome) * 12;
  const annualExpenses =
    propertyTaxes +
    managementFees +
    insurance +
    repairs +
    utilities +
    otherExpense;
  const netOperatingIncome = annualIncome - annualExpenses;
  const operatingExpenseRatio = annualExpenses / annualIncome;
  return {
    annualIncome: annualIncome,
    annualExpenses: annualExpenses,
    netOperatingIncome: netOperatingIncome,
    operatingExpenseRatio: operatingExpenseRatio,
  };
}

function capRate(values) {
  //console.log('[helpers.js] ƒ capRate', values);
  const {netIncome, propertyValue} = values;
  const capRate = netIncome / propertyValue;
  return {capRate: capRate};
}

function costCalculator(units = 1) {
  const plans = servicePlans;
  let costArr = [];
  plans.map((p, index) => {
    let unitCost =
      units > p.properties ? (units - p.properties) * p.unitPrice : 0.0;
    let subscriptionCost = p.subscriptionFee + unitCost;
    let achCost = units * p.achRate * 1500 + units * p.achFee;
    let totalCost = subscriptionCost + achCost;
    let costObj = {
      servicePlanName: p.name,
      totalCost: totalCost,
      subscriptionCost: subscriptionCost,
      achCost: achCost,
      bestValue: false,
    };
    return costArr.push(costObj);
  });
  let valuePlan = costArr.reduce((prev, curr) => {
    return prev.totalCost < curr.totalCost ? prev : curr;
  });
  valuePlan.bestValue = true;
  //console.log(valuePlan);
  return costArr;
}

const rentSplitCalculator = (values) => {
  // console.log('[helpers.js] ƒ rentSplitCalculator', values);
  const {bedrooms, rentAmount} = values;

  const calculatePerOccupantRent = (bedroom) => {
    let bedroomSqFootage = bedroom.privateBath
      ? bedroom.sqFootage + 100
      : bedroom.sqFootage;
    if (
      isNaN(
        (parseFloat(rentAmount) * (bedroomSqFootage / totalBedroomSqFootage)) /
          bedroom.occupants
      )
    )
      return 0;
    return (
      (parseFloat(rentAmount) * (bedroomSqFootage / totalBedroomSqFootage)) /
      bedroom.occupants
    );
  };

  //console.log(bedrooms);
  const totalBedroomSqFootage = bedrooms.reduce((a, b) => {
    return b.privateBath ? a + b.sqFootage + 100 : a + b.sqFootage;
  }, 0);

  //console.log('total bedroom sq. ft.', totalBedroomSqFootage);

  const newBedrooms = bedrooms.map((bedroom) => {
    bedroom.perOccupantRent = calculatePerOccupantRent(bedroom);
    return bedroom;
  });
  //console.log(newBedrooms);

  return {...values, bedrooms: newBedrooms};
};

const calculateRentAffordability = (values) => {
  // console.log('[helpers.js] ƒ calculateRentAffordability', values);
  const numericValues = {};
  for (const key in values) {
    numericValues[key] = values[key] ? parseFloat(values[key]) : 0;
  }

  const rentExpense =
    numericValues.netIncome * (numericValues.rentExpenseRatio / 100);

  const nonRentExpense =
    numericValues.transportation +
    numericValues.food +
    numericValues.insurance +
    numericValues.utilities +
    numericValues.entertainment +
    numericValues.otherExpense;

  const results = {
    monthlyIncome: numericValues.netIncome,
    otherExpenses: nonRentExpense,
    rentalExpense: rentExpense,
    totalMonthlyExpenses: nonRentExpense + rentExpense,
  };

  return results;
};

export {
  netOperatingIncome,
  roiCalculator,
  calculateMoveOutCosts,
  capRate,
  calculateMoveInCosts,
  costCalculator,
  rentSplitCalculator,
  calculateRentAffordability,
};
