import showdown from 'showdown';

const applyInlineStyles = (html) => {
  const tableStyle = 'width: 100%; border-collapse: collapse;';
  const thTdStyle = 'border: 1px solid black; padding: 8px; text-align: left;';
  const thStyle = 'background-color: #f2f2f2;';
  const bodyStyle = 'font-size: 16px;';
  const h1Style = 'font-size: 24px;';

  // Create a temporary DOM element to manipulate the HTML
  const tempDiv = document.createElement('div');
  tempDiv.innerHTML = html;

  // Apply styles to table elements
  tempDiv.querySelectorAll('table').forEach((table) => {
    table.setAttribute('style', tableStyle);
  });

  tempDiv.querySelectorAll('th, td').forEach((cell) => {
    cell.setAttribute('style', thTdStyle);
  });

  tempDiv.querySelectorAll('th').forEach((th) => {
    th.setAttribute('style', `${thTdStyle} ${thStyle}`);
  });

  // Apply styles to body and headings
  tempDiv.querySelectorAll('body').forEach((body) => {
    body.setAttribute('style', bodyStyle);
  });

  tempDiv.querySelectorAll('h1').forEach((h1) => {
    h1.setAttribute('style', h1Style);
  });

  return tempDiv.innerHTML;
};

// export function convertMarkdownToHtml(markdown) {
//   const marked = new Marked([
//     {
//       gfm: true,
//     },
//     extendedTables,
//   ]);
//   const rawHtml = marked.parse(markdown);
//   const sanitizedHtml = DOMPurify.sanitize(rawHtml);
//   return applyInlineStyles(sanitizedHtml);
// }

export function convertMarkdownToHtml(markdown) {
  const converter = new showdown.Converter();
  converter.setFlavor('github');
  const html = converter.makeHtml(markdown);
  return applyInlineStyles(html);
}
