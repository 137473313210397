import {ResultWrapper} from '../components.styles';
import {StyledNumber, ErrorText} from '../components.styles';
import {Typography, Row, Col} from 'antd';
import numeral from 'numeral';
import {useSpring} from 'react-spring';
const {Text} = Typography;

const RentAffordabilityResult = (props) => {
  // console.log('ƒ RentAffordabilityResult', props);
  const {monthlyIncome, otherExpenses, rentalExpense, totalMonthlyExpenses} =
    props;

  const incSpring = useSpring({
    from: {num: 0},
    to: {num: monthlyIncome},
  });
  const expSpring = useSpring({
    from: {num: 0},
    to: {num: otherExpenses},
  });

  const rentSpring = useSpring({
    from: {num: 0},
    to: {num: rentalExpense},
  });

  const totalSpring = useSpring({
    from: {num: 0},
    to: {num: totalMonthlyExpenses},
  });

  const overBudget = monthlyIncome - totalMonthlyExpenses < 0;

  return (
    <>
      <ResultWrapper>
        <Row gutter={24}>
          <Col span={12}>
            <Text style={{color: '#122c34', fontWeight: '700'}}>
              Montly Income
            </Text>
            <br />
            {incSpring < 0 ? (
              <ErrorText>Error. Check your settings. </ErrorText>
            ) : (
              ''
            )}
            <StyledNumber>
              {incSpring.num.to((v) => numeral(v).format('$0,0.00'))}
            </StyledNumber>
          </Col>
          <Col span={12}>
            <Text style={{color: '#122c34', fontWeight: '700'}}>
              Rent Expense
            </Text>
            <br />
            {rentSpring < 0 ? (
              <ErrorText>Error. Check your settings. </ErrorText>
            ) : (
              ''
            )}
            <StyledNumber>
              {rentSpring.num.to((v) => numeral(v).format('$0,0.00'))}
            </StyledNumber>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={12}>
            <Text
              style={{
                color: '#122c34',
                fontWeight: '700',
              }}
            >
              Other Expense
            </Text>
            <br />
            {expSpring < 0 ? (
              <ErrorText>Error. Check your settings. </ErrorText>
            ) : (
              ''
            )}
            <StyledNumber>
              {expSpring.num.to((v) => numeral(v).format('$0,0.00'))}
            </StyledNumber>
          </Col>
          <Col span={12}>
            <Text
              style={{
                color: overBudget ? '#ff4d4f' : '#122c34',
                fontWeight: '700',
              }}
            >
              Total Budget
            </Text>
            <br />
            {totalSpring < 0 ? (
              <ErrorText>Error. Check your settings. </ErrorText>
            ) : (
              ''
            )}
            <StyledNumber style={{color: overBudget && '#ff4d4f'}}>
              {totalSpring.num.to((v) => numeral(v).format('$0,0.00'))}
            </StyledNumber>
          </Col>
        </Row>
      </ResultWrapper>
    </>
  );
};
export default RentAffordabilityResult;
