import React from 'react';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';

const components = {
  p: ({node, ...props}) => <p style={{margin: '1em 0'}} {...props} />,
  table: ({node, ...props}) => (
    <table style={{borderCollapse: 'collapse', width: '100%'}} {...props} />
  ),
  thead: ({node, ...props}) => <thead {...props} />,
  tbody: ({node, ...props}) => <tbody {...props} />,
  tr: ({node, isHeader, ...props}) => <tr {...props} />,
  th: ({node, isHeader, ...props}) => (
    <th
      style={{
        border: '1px solid #ddd',
        padding: '8px',
        backgroundColor: '#f2f2f2',
        textAlign: 'left',
      }}
      {...props}
    />
  ),
  td: ({node, isHeader, ...props}) => (
    <td style={{border: '1px solid #ddd', padding: '8px'}} {...props} />
  ),
  code: ({node, inline, className, children, ...props}) => {
    const match = /language-(\w+)/.exec(className || '');
    return !inline && match ? (
      <pre
        style={{
          padding: '10px',
          backgroundColor: 'rgba(0, 0, 0, 0.05)',
          borderRadius: '12px',
        }}
        {...props}
      >
        <code className={className} {...props}>
          {children}
        </code>
      </pre>
    ) : (
      <code className={className} {...props}>
        {children}
      </code>
    );
  },
};

export default function StyledReactMarkdown({children}) {
  return (
    <ReactMarkdown
      children={children}
      remarkPlugins={[remarkGfm]}
      components={components}
    />
  );
}
