import React from 'react';
import {Layout, Row, Col, Card as AntCard, Typography, Button} from 'antd';
import {Link} from 'react-router-dom';
import {HashLink} from 'react-router-hash-link';
import styled from 'styled-components';
import EmbedPopover from '../components/EmbedPopover/EmbedPopover';
import Logo from '../components/Logo/Logo';

const {Title, Text} = Typography;

const {Content, Footer} = Layout;

const UseCalculatorButton = ({to, label}) => (
  <div style={{textAlign: 'right'}}>
    <Link to={to}>
      <Button>Use {label}</Button>
    </Link>
  </div>
);

export default function Home() {
  return (
    <Layout style={{height: '100vh'}}>
      <Content
        style={{
          overflow: 'auto',
        }}
      >
        <div className="content-container">
          <div style={{width: '100%'}}>
            <Logo />
          </div>
          <div className="content-wrapper">
            <Title level={2}>
              Free and Fabulous Tools to Help You Build Your Real Estate Empire
            </Title>
            <Text>
              Welcome to the PayRent WebApps landing page. Here you will find a
              collection of useful calculators and tools designed to help make
              renting easier and real estate management less of a hassle. These
              apps are designed to be embedded into any website and you are free
              to do so with permission and proper attribution. See the{' '}
              <HashLink to="/#acceptable-use">Acceptable Use</HashLink>{' '}
              guidelines below for more details.
            </Text>
          </div>
          <div>
            <Title level={2}>Apps</Title>
          </div>
          <Row
            gutter={[
              {
                xs: 8,
                sm: 16,
                md: 24,
                lg: 32,
              },
              {
                xs: 8,
                sm: 16,
                md: 24,
                lg: 32,
              },
            ]}
          >
            <Col xs={24} sm={12} lg={8}>
              <StyledCard title="Move-in Calculator" extra={<EmbedPopover />}>
                <p>
                  Calculate pro-rated rent and move-in costs to provide renters
                  with a total move-in amount.{' '}
                  <a href="https://www.payrent.com/articles/prorated-rent-calculator/">
                    Learn More...
                  </a>
                </p>
                <UseCalculatorButton
                  to="/move-in-calculator"
                  label="Move-in Calculator"
                />
              </StyledCard>
            </Col>
            <Col xs={24} sm={12} lg={8}>
              <StyledCard title="Move-out Calculator" extra={<EmbedPopover />}>
                <p>
                  Calculate pro-rated rent and end-of-lease costs for renters
                  moving out of a property.{' '}
                  <a href="https://www.payrent.com/articles/prorated-rent-calculator/">
                    Learn More...
                  </a>
                </p>
                <UseCalculatorButton
                  to="/move-out-calculator"
                  label="Move-out Calculator"
                />
              </StyledCard>
            </Col>
            <Col xs={24} sm={12} lg={8}>
              <StyledCard title="NOI Calculator" extra={<EmbedPopover />}>
                <p>
                  Calculate the net operating expense (NOI) and operating
                  expense ratio for a rental property.{' '}
                  <a href="https://www.payrent.com/articles/net-operating-income-calculator-noi/">
                    Learn More...
                  </a>
                </p>
                <UseCalculatorButton
                  to="/net-operating-income-calculator"
                  label="NOI Calculator"
                />
              </StyledCard>
            </Col>
            <Col xs={24} sm={12} lg={8}>
              <StyledCard title="ROI Calculator" extra={<EmbedPopover />}>
                <p>
                  Calculate the return on investment for your rental property to
                  compare it with other assets.{' '}
                  <a href="https://www.payrent.com/articles/roi-calculator-for-rental-property/">
                    Learn More...
                  </a>
                </p>
                <UseCalculatorButton
                  to="/roi-calculator"
                  label="ROI Calculator"
                />
              </StyledCard>
            </Col>
            <Col xs={24} sm={12} lg={8}>
              <StyledCard
                title="Rent Split Calculator"
                extra={<EmbedPopover />}
              >
                <p>
                  A handly tool for figuring out how to split rent between
                  roommates using common attributes.{' '}
                  <a href="https://www.payrent.com/articles/rent-split-calculator-how-to-split-rent-among-roommates/">
                    Learn More...
                  </a>
                </p>
                <UseCalculatorButton
                  to="/rent-split-calculator"
                  label="Rent Split Calculator"
                />
              </StyledCard>
            </Col>
            <Col xs={24} sm={12} lg={8}>
              <StyledCard title="Cap-rate Calculator" extra={<EmbedPopover />}>
                <p>
                  Calculate a property's cap-rate to understand the rate of
                  return on your investment.{' '}
                  <a href="https://www.payrent.com/articles/cap-rate-calculator/">
                    Learn More...
                  </a>{' '}
                </p>
                <UseCalculatorButton
                  to="/cap-rate-calculator"
                  label="Cap-rate Calculator"
                />
              </StyledCard>
            </Col>
            <Col xs={24} sm={12} lg={8}>
              <StyledCard
                title="Affordability Calculator"
                extra={<EmbedPopover />}
              >
                <p>
                  Calculate how much rent a tenant can afford based on income
                  and other factors.{' '}
                  <a href="https://www.payrent.com/rental-property-calculators/rent-affordability-calculator/">
                    Learn More...
                  </a>{' '}
                </p>
                <UseCalculatorButton
                  to="/affordability-calculator"
                  label="Affordability Calculator"
                />
              </StyledCard>
            </Col>
            <Col xs={24} sm={12} lg={8}>
              <StyledCard title="Geni AI Assistant">
                <p>
                  Use Geni&trade; to complete common AI tasks like generating
                  letters and emails.
                </p>
                <UseCalculatorButton
                  to="/geni/chat"
                  label="Geni AI Assistant"
                />
              </StyledCard>
            </Col>
            <Col xs={24} sm={12} lg={8}>
              <StyledCard title="Document Creator">
                <p>
                  Quickly generate commonly used documents send them
                  electronically via email.{' '}
                </p>
                <UseCalculatorButton
                  to="/document/create"
                  label="Document Creator"
                />
              </StyledCard>
            </Col>
            <Col xs={24} sm={12} lg={8}>
              <StyledCard title="Pricing Calculator">
                <p>
                  Estimate monthly cost to use PayRent's property management
                  platform for your property holdings.
                </p>
                <UseCalculatorButton
                  to="/pricing-calculator"
                  label="Pricing Calculator"
                />
              </StyledCard>
            </Col>
          </Row>
          <div className="content-wrapper" id="acceptable-use">
            <Title level={2}>Terms of Use</Title>
            <Text>
              PayRent Inc. ("Company") provides this website at{' '}
              <a href="https://www.payrent.com">payrent.com</a> (referred to
              hereafter as "the Website") for use by consumers and businesses.
              By using the Website in any way, you are agreeing to comply with
              the Terms of Use. Should you object to any term or condition of
              the Terms of Use or any guideline of the Website, your only
              recourse is to immediately discontinue use of the Website.{' '}
            </Text>
            <Text>
              You agree not to post any text, files, images, video, audio, or
              other materials ("Content") or use the Website in any way that:
              <ul>
                <li>
                  infringes any patent, trademark, trade secret, copyright, or
                  other proprietary rights of any party;
                </li>
                <li>violates any state, federal, or other law;</li>
                <li>threatens, harasses, or is libelous;</li>
                <li>
                  is harmful to minors; contains self-benefiting advertising or
                  marketing in public areas of the Website that have not been
                  paid for and are not designated for the addition of
                  promotional content;
                </li>
                <li>
                  produces Software viruses or code harmful to other computers;
                  disrupts the normal dialogue of users of the Website;
                </li>
                <li>employs misleading or false information;</li>
                <li>
                  uses forged headers or other items to manipulate identifiers
                  in order to disguise the origin of Content.
                </li>
              </ul>
            </Text>
            <Text>
              You agree not to decompile or reverse engineer or otherwise
              attempt to discover any source code contained in the Website.
              Unless you receive explicit permission, you agree not to
              reproduce, duplicate, copy, sell, resell, or exploit for any
              commercial purposes, any aspect of the Website. We reserve the
              right, at our sole discretion, to change, modify, or alter the
              Terms of Use at any time.
            </Text>
          </div>
          <div className="content-wrapper">
            <Title level={2}>Privacy Policy and Data Collection</Title>
            <Text>
              Please review our{' '}
              <a href="https://www.payrent.com/privacy-policy-2022/">
                Privacy Policy
              </a>{' '}
              for details on the Company's collection, disclosure, and use of
              personally identifiable information and privacy matters. Use of
              the Website is based on your agreement to the Privacy Policy and
              Terms of Use.
            </Text>
          </div>
        </div>
      </Content>
      <Footer style={{textAlign: 'center'}}>
        Copyright PayRent Inc. All Rights Reserved &copy; 2023
      </Footer>
    </Layout>
  );
}

const StyledCard = styled(AntCard)`
  height: 100%;
  min-height: 200px;
  box-shadow: 0 6px 16px 0 rgba(0, 0, 0, 0.03);
`;
