import React from 'react';
import {StyledCard, WidgetWrapper} from '../App.styles';
import CalculatorForm from '../components/calculatorforms/RentAffordabilityCalculatorForm';
import {Actions} from '../library/helpers/actions';
import Logo from '../components/Logo/Logo';

class RentAffordabilityCalculator extends React.PureComponent {
  render() {
    return (
      <WidgetWrapper className="rac-widget">
        <StyledCard
          title="Rent Affordability"
          className="rac-card"
          extra={
            <Actions
              handlePrint={this.props.handlePrint}
              showAction={['print', 'share']}
            />
          }
          ref={this.props.ref}
        >
          <CalculatorForm />
          <Logo />
        </StyledCard>
      </WidgetWrapper>
    );
  }
}

export default RentAffordabilityCalculator;
