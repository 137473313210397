import React from 'react';
import jsPDF from 'jspdf';
import HtmlToPdfTemplate from '../../components/HtmlToPdfTemplate/HtmlToPdfTemplate';
import {renderToStaticMarkup} from 'react-dom/server';

export const generatePdf = async (document) => {
  // console.log('ƒ generatePdf');
  let generatedPdf;
  const doc = new jsPDF('p', 'pt', 'letter');

  const HtmlDocument = renderToStaticMarkup(
    <HtmlToPdfTemplate>
      <div dangerouslySetInnerHTML={{__html: document}} />
    </HtmlToPdfTemplate>
  ).toString();
  // console.log(HtmlDocument);

  const opts = {
    autoPaging: 'text',
    html2canvas: {
      allowTaint: true,
      dpi: 300,
      letterRendering: true,
      logging: false,
      scale: 0.8,
      x: 0,
      y: 0,
    },
    callback(doc) {
      const pageCount = doc.internal.getNumberOfPages();

      for (var i = 1; i <= pageCount; i++) {
        doc.text(
          'Created using PayRent Document Creator \u00A9 2023',
          196,
          772
        );
      }
      generatedPdf = doc.output('blob');
    },
  };

  await doc.html(HtmlDocument, opts);
  return generatedPdf;
};
